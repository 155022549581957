import React, { useState, useEffect, useContext } from "react";
import ReactPaginate from "react-paginate";
import {
  MdOutlineArrowBackIos,
  MdOutlineArrowForwardIos,
} from "react-icons/md";
import { AiOutlineSearch } from "react-icons/ai";
import TextField from "@mui/material/TextField";
import Navbar from "../components/common/Navbar";
import userData from "../api/user";
import UserTable from "../components/user-table/UserTable";
import Loading from "../components/material-ui/Loading";
import { AuthContext } from "../context/Auth/AuthContext";
import AddUser from "../components/user-table/AddUser";
import baseUrl from "../config/baseUrl";
import "../styles/Users.css";

const Users = () => {
  const [loading, setLoading] = useState(true);
  const [currentPage, setCurrentPage] = useState(0);
  const [pageCount, setPageCount] = useState(0);
  const [showAddUserForm, setShowAddUserForm] = useState(false);

  const [userAdded, setUserAdded] = useState(0);
  const [globalSearch, setGlobalSearch] = useState("");

  const [userResponse, setUserResponse] = useState([]);
  const [api, setApi] = useState(
    `${baseUrl}/apireact/alluser?limit=20&offset=0`
  );

  let limit = 20;
  const offset = currentPage * limit;

  let global_search = globalSearch ? `&global_search=${globalSearch}` : "";

  const { token, isAdmin } = useContext(AuthContext);

  const UserResponseFunc = async () => {
    setLoading(true);
    const users = await userData({ token, api });
    setPageCount(Math.ceil(users?.allUsersCount / limit));
    setUserResponse(users?.data);
    setLoading(false);
  };

  useEffect(() => {
    UserResponseFunc();
  }, [api, userAdded]);

  const onAddUserButtonClick = () => {
    setShowAddUserForm(!showAddUserForm);
  };

  const onGlobalSearchChange = (e) => {
    setGlobalSearch(e.target.value);
  };

  const setUserAddedToTrue = () => {
    setUserAdded((count) => count + 1);
  };

  useEffect(() => {
    setApi(
      `${baseUrl}/apireact/alluser?limit=${limit}&offset=${offset}${global_search}`
    );
  }, [currentPage]);

  useEffect(() => {
    const debounceFn = setTimeout(() => {
      setCurrentPage(0);
      setApi(
        `${baseUrl}/apireact/alluser?limit=${20}&offset=${0}${global_search}`
      );
    }, 1000);

    return () => clearTimeout(debounceFn);
  }, [globalSearch]);

  const handlePageClick = ({ selected: selectedPage }) => {
    setCurrentPage(selectedPage);
  };

  return (
    <div className="user-container">
      <div className="navbar">
        <Navbar />
      </div>
      <div className="user-table">
        {showAddUserForm ? (
          <AddUser
            onAddUserButtonClick={onAddUserButtonClick}
            setUserAddedToTrue={setUserAddedToTrue}
          />
        ) : (
          <div className="user-content">
            <div className="user-table-header">
              <TextField
                name="globalSearch"
                label="Search"
                type="text"
                autoComplete="off"
                value={globalSearch}
                onChange={onGlobalSearchChange}
                variant="outlined"
                InputProps={{
                  endAdornment: <AiOutlineSearch />,
                }}
              />
              {isAdmin === "1" ? (
                <button className="add-user" onClick={onAddUserButtonClick}>
                  Add User
                </button>
              ) : null}
            </div>
            {!loading ? (
              userResponse.length > 0 ? (
                <div className="user-table-content">
                  <UserTable
                    loading={loading}
                    userTableData={userResponse}
                    setUserAddedToTrue={setUserAddedToTrue}
                  />
                  <div className="user-table-pagination">
                    <ReactPaginate
                      previousLabel={<MdOutlineArrowBackIos />}
                      nextLabel={<MdOutlineArrowForwardIos />}
                      pageCount={pageCount}
                      onPageChange={handlePageClick}
                      forcePage={currentPage}
                      containerClassName={"user-pagination"}
                      previousLinkClassName={"user-pagination-link"}
                      nextLinkClassName={"user-pagination-link"}
                      disabledClassName={"user-pagination-link-disabled"}
                      activeClassName={"user-pagination-link-active"}
                    />
                  </div>
                </div>
              ) : (
                <h5 className="no-user-data">No Data Available</h5>
              )
            ) : (
              <Loading />
            )}
          </div>
        )}
      </div>
    </div>
  );
};

export default Users;

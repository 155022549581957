import React, { useMemo, useContext } from "react";
import { format } from "date-fns";
import { useTable, useRowSelect } from "react-table";
import { toast } from "react-toastify";
import { AuthContext } from "../../context/Auth/AuthContext";
import removeDocumentsFromFavourite from "../../api/removeBulkFavouriteDocuments";
import { Columns } from "./Column";
import { fileIcon } from "../common/FileIcons";
import Loading from "../material-ui/Loading";
import "../../styles/Table.css";
import removeDocumentFromFavourite from "../../api/removeFavouriteDocument";

const StarredTable = ({
  loading,
  removeDocumentsFromFavouriteList,
  starredDocumentsResponse,
}) => {
  const columns = useMemo(() => Columns, []);
  const data = useMemo(
    () => starredDocumentsResponse,
    [starredDocumentsResponse]
  );

  const { token } = useContext(AuthContext);

  const { getTableProps, getTableBodyProps, headerGroups, rows, prepareRow } =
    useTable(
      {
        columns: columns,
        data: data,
        initialState: {
          hiddenColumns: ["id", "bg_color"],
        },
      },
      useRowSelect,
      (hooks) => {
        hooks.visibleColumns.push((columns) => {
          return [
            {
              id: "starred",
              Cell: ({ row }) => (
                <>
                  <input
                    className="star-checkbox"
                    type="checkbox"
                    checked={true}
                    onChange={(e) => onDocumentCheck(e, row)}
                  />
                </>
              ),
            },
            ...columns,
          ];
        });
      }
    );

  const onStarredHeaderCheck = async (e) => {
    let docsID = starredDocumentsResponse.map((doc) => {
      return `'${doc.id}'`;
    });

    const removeDocumentsFromFavouriteResponse =
      await removeDocumentsFromFavourite({
        token: token,
        id: docsID,
      });

    if (removeDocumentsFromFavouriteResponse.code === 200) {
      removeDocumentsFromFavouriteList(true);
      toast.success("Documents are removed from favourite list", {
        position: "top-center",
        autoClose: 3000,
        theme: "dark",
        hideProgressBar: false,
        closeOnClick: true,
        pauseOnHover: true,
        draggable: true,
        progress: undefined,
      });
    }
  };

  const onDocumentCheck = async (e, row) => {
    const removeDocumentFromFavouriteResponse =
      await removeDocumentFromFavourite({
        token: token,
        id: row.original.id,
      });

    if (removeDocumentFromFavouriteResponse.code === 200) {
      removeDocumentsFromFavouriteList(false);
      toast.success("Document is removed from favourite list", {
        position: "top-center",
        autoClose: 3000,
        theme: "dark",
        hideProgressBar: false,
        closeOnClick: true,
        pauseOnHover: true,
        draggable: true,
        progress: undefined,
      });
    }
  };

  const StarredHeader = () => {
    return (
      <input
        type="checkbox"
        className="star-checkbox"
        checked={true}
        onChange={onStarredHeaderCheck}
      />
    );
  };

  return (
    <div className="table-content">
      {!loading ? (
        <table {...getTableProps()} className="documents-table starred-tables">
          <thead className="documents-table-header starred-tables-header">
            {headerGroups.map((headerGroup, index) => {
              return (
                <tr
                  key={index}
                  {...headerGroup.getHeaderGroupProps()}
                  className="documents-table-header-row"
                >
                  {headerGroup.headers.map((column, index) => (
                    <th
                      key={index}
                      {...column.getHeaderProps()}
                      className="documents-table-header-data"
                    >
                      {column.id == "starred" ? (
                        <StarredHeader />
                      ) : (
                        column.render("Header")
                      )}
                    </th>
                  ))}
                </tr>
              );
            })}
          </thead>
          <tbody
            className="documents-table-body starred-tables-body"
            {...getTableBodyProps()}
          >
            {rows.map((row, index) => {
              prepareRow(row);
              return (
                <tr
                  key={index}
                  {...row.getRowProps()}
                  className="document-table-body-row"
                >
                  {row.cells.map((cell, index) => {
                    switch (cell.column.Header) {
                      case "File":
                        return (
                          <td key={index} className="file-icon">
                            {fileIcon[cell.value]}
                          </td>
                        );
                      case "Category":
                        return (
                          <td key={index}>
                            <p
                              className="category"
                              style={{
                                background: `${
                                  cell.row.values.bg_color ||
                                  "rgba(153, 102, 255, 0.2)"
                                }`,
                              }}
                            >
                              {cell.row.values.badge || "Other"}
                            </p>
                          </td>
                        );
                      case "Uploaded":
                        return (
                          <td key={index}>
                            {cell.value !== "0000-00-00" &&
                            cell.value !== "0000-00-00 00:00:00"
                              ? format(new Date(cell.value), "MM-dd-yyyy")
                              : "N/A"}
                          </td>
                          // <td key={index}>{cell.value?.substring(0, 10)}</td>
                        );
                      default:
                        return (
                          <td key={index} {...cell.getCellProps()}>
                            {cell.render("Cell")}
                          </td>
                        );
                    }
                  })}
                </tr>
              );
            })}
          </tbody>
        </table>
      ) : (
        <Loading />
      )}
    </div>
  );
};

export default StarredTable;

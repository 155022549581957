import React, { useState, useEffect, useContext } from "react";
import ReactPaginate from "react-paginate";
import {
  MdOutlineArrowBackIos,
  MdOutlineArrowForwardIos,
} from "react-icons/md";
import { AiOutlineSearch } from "react-icons/ai";
import TextField from "@mui/material/TextField";
import { AuthContext } from "../../context/Auth/AuthContext";
import caseListData from "../../api/caselist";
import Table from "./Table";
import Loading from "../material-ui/Loading";
import baseUrl from "../../config/baseUrl";
import "../../styles/DocumentTable.css";

const CaseListTable = ({ addDocumentTab }) => {
  const [loading, setLoading] = useState(true);
  const [currentPage, setCurrentPage] = useState(0);
  const [pageCount, setPageCount] = useState(0);

  const [documentResponse, setDocumentResponse] = useState([]);

  const [globalSearch, setGlobalSearch] = useState("");

  const { token, userID, isAdmin } = useContext(AuthContext);

  const [api, setApi] = useState(
    `${baseUrl}/caselist/get_all_documents_related_cases?limit=${
      currentPage + 1
    }&userId=${userID}&isAdmin=${isAdmin}`
  );

  let searchValue = globalSearch ? `&searchValue=${globalSearch}` : "";

  const documentResponseFunc = async () => {
    setLoading(true);
    const response = await caseListData({ token, api });
    setPageCount(Math.ceil(response?.totalCount / 20));
    setDocumentResponse(
      response?.data?.length !== 0 || response?.data !== null
        ? response?.data
        : []
    );
    setLoading(false);
  };

  useEffect(() => {
    setApi(
      `${baseUrl}/caselist/get_all_documents_related_cases?limit=${
        currentPage + 1
      }&userId=${userID}&isAdmin=${isAdmin}${searchValue}`
    );
  }, [currentPage]);

  useEffect(() => {
    const debounceFn = setTimeout(() => {
      setCurrentPage(0);
      setApi(
        `${baseUrl}/caselist/get_all_documents_related_cases?limit=${1}&userId=${userID}&isAdmin=${isAdmin}${searchValue}`
      );
    }, 2500);

    return () => clearTimeout(debounceFn);
  }, [globalSearch]);

  useEffect(() => {
    documentResponseFunc();
  }, [api]);

  const onGlobalSearchChange = (e) => {
    setGlobalSearch(e.target.value);
  };

  const handlePageClick = ({ selected: selectedPage }) => {
    setCurrentPage(selectedPage);
  };

  return (
    <div className="document-table">
      {
        <div className="documents-content">
          <div className="table-header">
            <TextField
              name="globalSearch"
              label="Search"
              type="text"
              autoComplete="off"
              value={globalSearch}
              onChange={onGlobalSearchChange}
              variant="outlined"
              InputProps={{
                endAdornment: <AiOutlineSearch />,
              }}
            />
          </div>
          {!loading ? (
            documentResponse?.length !== 0 ? (
              <div className="document__table">
                <Table
                  loading={loading}
                  documentResponse={documentResponse}
                  addDocumentTab={addDocumentTab}
                />
                <div className="table-pagination">
                  <ReactPaginate
                    previousLabel={<MdOutlineArrowBackIos />}
                    nextLabel={<MdOutlineArrowForwardIos />}
                    pageCount={pageCount}
                    onPageChange={handlePageClick}
                    forcePage={currentPage}
                    containerClassName={"doc-pagination"}
                    previousLinkClassName={"doc-pagination-link"}
                    nextLinkClassName={"doc-pagination-link"}
                    disabledClassName={"doc-pagination-link-disabled"}
                    activeClassName={"doc-pagination-link-active"}
                  />
                </div>
              </div>
            ) : (
              <h5 className="no-doc-data">No Data Available</h5>
            )
          ) : (
            <Loading />
          )}
        </div>
      }
    </div>
  );
};

export default CaseListTable;

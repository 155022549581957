import React, { useState, useEffect, useContext } from "react";
import ReactPaginate from "react-paginate";
import {
  MdOutlineArrowBackIos,
  MdOutlineArrowForwardIos,
} from "react-icons/md";
import TextField from "@mui/material/TextField";
import InputLabel from "@mui/material/InputLabel";
import MenuItem from "@mui/material/MenuItem";
import FormControl from "@mui/material/FormControl";
import Select from "@mui/material/Select";
import { AiOutlineSearch } from "react-icons/ai";
import requestedDocumentList from "../../../api/requestedDocumentList";
import { AuthContext } from "../../../context/Auth/AuthContext";
import Loading from "../../material-ui/Loading";
import RequestedDocumentsTable from "./RequestedDocumentsTable";
import RequestDocumentsPopup from "./RequestDocumentsPopup";
import baseUrl from "../../../config/baseUrl";

const RequestedDocumentsList = ({
  caseNo,
  caseID,
  contactID,
  status,
  serviceType,
  serviceSubType,
  caseDocumentsType,
  selectedCaseDocumentsType,
  changeSelectedCaseDocumentsType,
}) => {
  const [loading, setLoading] = useState(false);
  const [currentPage, setCurrentPage] = useState(0);
  const [pageCount, setPageCount] = useState(0);
  const [selectedDocumentRequestedArray, setSelectedDocumentRequestedArray] =
    useState("All");
  const [categoryUpdated, setCategoryUpdated] = useState(0);

  const [documentResponse, setDocumentResponse] = useState([]);
  const [documentTypeArray, setDocumentTypeArray] = useState([]);

  const [globalSearch, setGlobalSearch] = useState("");

  let searchValue = globalSearch ? `&searchValue=${globalSearch}` : "";
  let docStatus = `&status=${selectedDocumentRequestedArray}`;

  const { token, userID, isAdmin } = useContext(AuthContext);

  const [api, setApi] = useState(
    `${baseUrl}/caselist/get_all_request_document?caseId=${caseID}&limit=${
      currentPage + 1
    }&userId=${userID}&isAdmin=${isAdmin}${docStatus}`
  );

  const documentRequestedArray = ["All", "Required", "Recommended"];

  const setCategoryUpdatedToTrue = () => {
    setCategoryUpdated((count) => count + 1);
  };

  const documentResponseFunc = async () => {
    if (!categoryUpdated) {
      setLoading(true);
    }
    const response = await requestedDocumentList({ token, api });
    setPageCount(Math.ceil(response?.totalCount / 20));
    setDocumentResponse(response?.data?.length !== 0 ? response?.data : []);

    // const docType =
    //   response?.data?.length !== 0
    //     ? response?.data?.map((doc) => {
    //         let name = doc.name;
    //         let result = name.split("IMM-");
    //         return result[1];
    //       })
    //     : [];

    // let filterDocType = "";
    // for (let i = 0; i < docType.length; i++) {
    //   i === docType.length - 1
    //     ? (filterDocType = filterDocType + ` '${docType[i]}'`)
    //     : (filterDocType = filterDocType + ` '${docType[i]}',`);
    // }

    // setDocumentTypeArray(filterDocType);

    if (!categoryUpdated) {
      setLoading(false);
    }
  };

  useEffect(() => {
    setApi(
      `${baseUrl}/caselist/get_all_request_document?caseId=${caseID}&limit=${
        currentPage + 1
      }&userId=${userID}&isAdmin=${isAdmin}${searchValue}${docStatus}`
    );
  }, [currentPage]);

  useEffect(() => {
    setCurrentPage(0);
    setApi(
      `${baseUrl}/caselist/get_all_request_document?caseId=${caseID}&limit=${1}&userId=${userID}&isAdmin=${isAdmin}${searchValue}${docStatus}`
    );
  }, [selectedDocumentRequestedArray]);

  useEffect(() => {
    const debounceFn = setTimeout(() => {
      setCurrentPage(0);
      setApi(
        `${baseUrl}/caselist/get_all_request_document?caseId=${caseID}&limit=${1}&userId=${userID}&isAdmin=${isAdmin}${searchValue}${docStatus}`
      );
    }, 2500);

    return () => clearTimeout(debounceFn);
  }, [globalSearch]);

  useEffect(() => {
    documentResponseFunc();
  }, [api, categoryUpdated]);

  const onGlobalSearchChange = (e) => {
    setGlobalSearch(e.target.value);
  };

  const handlePageClick = ({ selected: selectedPage }) => {
    setCurrentPage(selectedPage);
  };

  const changeDocumentViewerState = (link, name) => {
    window.open(link);
  };

  return (
    <>
      <div className="case-doc-table-header">
        <TextField
          name="globalSearch"
          label="Search"
          type="text"
          autoComplete="off"
          value={globalSearch}
          onChange={onGlobalSearchChange}
          className="case-doc-textfield"
          variant="outlined"
          InputProps={{
            endAdornment: <AiOutlineSearch />,
          }}
        />
        <div className="case-doc-table-header-part">
          <RequestDocumentsPopup
            caseID={caseID}
            contactID={contactID}
            status={status}
            documentTypeArray={documentTypeArray}
            setCategoryUpdatedToTrue={setCategoryUpdatedToTrue}
            serviceType={serviceType}
            serviceSubType={serviceSubType}
          />
          <FormControl className="case-doc-requested-select-ui">
            <InputLabel className="case-doc-select-label" id="select-label">
              Status
            </InputLabel>
            <Select
              name="addDocCategories"
              sx={{ margin: "10px 0px", maxWidth: "20" }}
              labelId="select-label"
              variant="outlined"
              id="simple-select"
              value={selectedDocumentRequestedArray}
              onChange={(e) =>
                setSelectedDocumentRequestedArray(e.target.value)
              }
              label="Status"
            >
              {documentRequestedArray.map((option) => (
                <MenuItem key={option} value={option}>
                  {option}
                </MenuItem>
              ))}
            </Select>
          </FormControl>
          <FormControl className="case-doc-select-ui">
            <InputLabel className="case-doc-select-label" id="select-label">
              Category
            </InputLabel>
            <Select
              name="addDocCategories"
              sx={{ margin: "10px 0px", maxWidth: "20" }}
              labelId="select-label"
              variant="outlined"
              id="simple-select"
              value={selectedCaseDocumentsType}
              onChange={changeSelectedCaseDocumentsType}
              label="Category"
            >
              {caseDocumentsType.map((option) => (
                <MenuItem key={option} value={option}>
                  {option}
                </MenuItem>
              ))}
            </Select>
          </FormControl>
        </div>
      </div>
      {!loading ? (
        documentResponse.length !== 0 ? (
          <div className="document__table">
            <RequestedDocumentsTable
              caseNo={caseNo}
              caseID={caseID}
              changeDocumentViewerState={changeDocumentViewerState}
              setCategoryUpdatedToTrue={setCategoryUpdatedToTrue}
              documentResponse={documentResponse}
            />
            <div className="table-pagination">
              <ReactPaginate
                previousLabel={<MdOutlineArrowBackIos />}
                nextLabel={<MdOutlineArrowForwardIos />}
                pageCount={pageCount}
                onPageChange={handlePageClick}
                forcePage={currentPage}
                containerClassName={"doc-pagination"}
                previousLinkClassName={"doc-pagination-link"}
                nextLinkClassName={"doc-pagination-link"}
                disabledClassName={"doc-pagination-link-disabled"}
                activeClassName={"doc-pagination-link-active"}
              />
            </div>
          </div>
        ) : (
          <h5 className="no-doc-data">No Data Available</h5>
        )
      ) : (
        <Loading />
      )}
    </>
  );
};

export default RequestedDocumentsList;

import React, { useState, useEffect, useContext } from "react";
import { v4 as uuidv4 } from "uuid";
import pdfDetails from "../../api/pdfDetails";
import { AuthContext } from "../../context/Auth/AuthContext";
import Loading from "../material-ui/Loading";
import PdfDetails from "./PdfDetails";
import PdfVersion from "./PdfVersion";
import PdfEditor from "./PdfEditor";
import { TbEdit } from "react-icons/tb";
import { IoArrowBackCircleSharp, IoCloseSharp } from "react-icons/io5";
import { IoDocumentText } from "react-icons/io5";
import { BiDetail } from "react-icons/bi";
import { TbVersions, TbListDetails } from "react-icons/tb";
import { FaHistory } from "react-icons/fa";
import { RiCloseCircleFill } from "react-icons/ri";
import "../../styles/PdfViewer.css";

const PdfViewer = ({
  documentType,
  caseNumber,
  documentID,
  documentTitle,
  changeActiveTab,
}) => {
  const [isPdfDetailsContainerOpen, setIsPdfDetailsContainerOpen] =
    useState(true);
  const [pdfData, setPdfData] = useState({});
  const [editor, setEditor] = useState(false);
  const [pdfBase64, setPdfBase64] = useState("");

  const [pdfTab, setPdfTab] = useState([
    {
      id: uuidv4(),
      title: <IoDocumentText />,
      content: (pdfData) => (
        <PdfDetails
          pdfData={pdfData}
          documentID={documentID}
          documentType={documentType}
        />
      ),
    },
    {
      id: uuidv4(),
      title: <TbVersions />,
      content: (documentID) => <PdfVersion documentID={documentID} />,
    },
  ]);
  const [value, setValue] = useState(pdfTab[0].id);
  const [loading, setLoading] = useState(true);
  const [pdfDataLoading, setPdfDataLoading] = useState(true);

  const { token } = useContext(AuthContext);

  const pdfDetailsData = async () => {
    const response = await pdfDetails(token, documentID);
    setPdfData(response?.data);
    setPdfBase64(response?.data?.pdfBlobData);
    setLoading(false);
    setPdfDataLoading(false);
  };

  useEffect(() => {
    pdfDetailsData();
  }, []);

  const handleChange = (index) => {
    setValue(index);
  };

  const changePdfDetailsContainerVisibility = () => {
    setIsPdfDetailsContainerOpen(!isPdfDetailsContainerOpen);
  };

  return (
    <>
      {!loading ? (
        <div className="pdf-viewer-container">
          {!editor ? (
            <div className="pdf-viewer">
              <div className="pdf-viewer-header">
                <div className="pdf-document-title">{documentTitle}</div>
                <div className="view-pdf-doc-icon">
                  {documentType === "pdf" ? (
                    <div
                      onClick={() => setEditor(!editor)}
                      className="edit-doc-btn"
                      title="Edit PDF"
                    >
                      <TbEdit />
                    </div>
                  ) : null}
                  <div
                    className="pdf-viewer-icon"
                    onClick={changePdfDetailsContainerVisibility}
                    title="PDF Details"
                  >
                    {isPdfDetailsContainerOpen ? (
                      <IoCloseSharp />
                    ) : (
                      <BiDetail />
                    )}
                  </div>
                </div>
              </div>
              <div className="iframe-wrapper">
                {documentType === "pdf" ? (
                  <iframe
                    title={documentTitle}
                    src={pdfData?.document_url}
                    width="100%"
                    height="590"
                  ></iframe>
                ) : (
                  <img src={pdfData?.document_url} width="100%" height="590" />
                )}
              </div>
              <div
                className={
                  isPdfDetailsContainerOpen
                    ? "pdf-details-container"
                    : "hide-pdf-details-container"
                }
              >
                <div className="pdf-details-header">
                  <div className="pdf-details-header-icon">
                    <IoDocumentText />
                  </div>
                  <div className="pdf-details-header-title">
                    Document Details
                  </div>
                  <div
                    className="close-pdf-details-container-icon"
                    onClick={changePdfDetailsContainerVisibility}
                  >
                    <RiCloseCircleFill />
                  </div>
                </div>
                <div className="pdf-details-content">
                  <PdfDetails
                    pdfData={pdfData}
                    documentID={documentID}
                    documentType={documentType}
                  />
                </div>
                <div className="pdf-details-content">
                  <PdfVersion documentID={documentID} />
                </div>
                {/* <div className="pdf-details-content">
                  <PdfHistory documentID={documentID} />
                </div> */}
              </div>
            </div>
          ) : !pdfDataLoading ? (
            <div className="show-pdf-editor">
              <div
                onClick={() => setEditor(!editor)}
                className="go-back-to-pdf-viewer"
                title="Back"
              >
                <IoArrowBackCircleSharp />
              </div>
              <div>
                <PdfEditor
                  pdfBase64={pdfBase64}
                  documentID={documentID}
                  documentTitle={documentTitle}
                  caseNumber={caseNumber}
                  changeActiveTab={changeActiveTab}
                />
              </div>
            </div>
          ) : (
            <Loading />
          )}
        </div>
      ) : (
        <Loading />
      )}
    </>
  );
};

export default PdfViewer;

{
  /* <div className="pdf-viewer-tabs-header">
                  {pdfTab.map((tab, index) => (
                    <div
                      key={tab.id}
                      className={
                        value === tab.id
                          ? "pdf-viewer-active-tab-title pdf-viewer-tab-title"
                          : "pdf-viewer-tab-title"
                      }
                      onClick={() => handleChange(tab.id)}
                    >
                      <span>{tab.title}</span>
                    </div>
                  ))}
                </div>
                {pdfTab.map((tab, index) => {
                  return (
                    <div
                      key={tab.id}
                      hidden={value !== tab.id}
                      id={`simple-tabpanel-${tab.id}`}
                      aria-labelledby={`simple-tab-${tab.id}`}
                    >
                      <div className="doc-detail-tabs">
                        {tab.title !== "Details"
                          ? tab.content(documentID)
                          : tab.content(pdfData)}
                      </div>
                    </div>
                  );
                })} */
}

import React, { useState, useContext, useEffect } from "react";
import { FaEye } from "react-icons/fa";
import { TiDeleteOutline } from "react-icons/ti";
import ButtonUI from "../../material-ui/ButtonUI";
import { AuthContext } from "../../../context/Auth/AuthContext";
import downloadLink from "../../../api/downloadLink";
import { caseListBaseUrl } from "../../../config/baseUrl";
import "../../../styles/LinkPopup.css";

const DocumentViewer = ({ tableValues, changeDocumentViewerState }) => {
  const { token } = useContext(AuthContext);

  const openLinkInNewTab = async () => {
    const downloadLinkFormResponse = await downloadLink({
      token: token,
      caseNo: tableValues.caseid,
      versionId: tableValues.DocumentID,
      platform: "AWS",
    });

    if (downloadLinkFormResponse?.code === 200) {
      const link = downloadLinkFormResponse?.data;
      console.log(`${caseListBaseUrl}/${link}`);
      changeDocumentViewerState(
        tableValues.DocumentID,
        `${caseListBaseUrl}/${link}`,
        tableValues.title,
        tableValues.Category,
        tableValues.document_desc
      );
    }
  };

  return (
    <div className="single-link-popup-container">
      <ButtonUI
        formButton={false}
        variant="outlined"
        buttonText={<FaEye />}
        style={{
          backgroundColor: "#ffc107",
          color: "white",
          marginRight: "5px",
        }}
        onClickFunc={openLinkInNewTab}
      />
    </div>
  );
};

export default DocumentViewer;

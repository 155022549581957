import React, { useState, useContext, useEffect } from "react";
import { AiOutlineSearch } from "react-icons/ai";
import ReactPaginate from "react-paginate";
import TextField from "@mui/material/TextField";
import {
  MdOutlineArrowBackIos,
  MdOutlineArrowForwardIos,
} from "react-icons/md";
import Navbar from "../components/common/Navbar";
import Table from "../components/bin/category-bin/Table";
import binCategory from "../api/binCategory";
import Loading from "../components/material-ui/Loading";
import { AuthContext } from "../context/Auth/AuthContext";
import baseUrl from "../config/baseUrl";
import "../styles/TrashCategory.css";

const TrashCategory = () => {
  const [loading, setLoading] = useState(false);
  const [currentPage, setCurrentPage] = useState(0);
  const [pageCount, setPageCount] = useState(0);
  const [globalSearch, setGlobalSearch] = useState("");

  const [trashedCategory, setTrashedCategory] = useState([]);
  const { token, userID, isAdmin } = useContext(AuthContext);
  const [api, setApi] = useState(
    `${baseUrl}/apireact/alltrashcategory?limit=20&offset=0&userId=${userID}&isAdmin=${isAdmin}`
  );

  const [categoryRestored, setCategoryRestored] = useState(0);
  const [categoryDeleted, setCategoryDeleted] = useState(0);

  let limit = 20;
  const offset = currentPage * limit;

  let global_search = globalSearch ? `&global_search=${globalSearch}` : "";

  const trashedCategoryData = async () => {
    setLoading(true);
    const response = await binCategory({ token, api });
    setPageCount(Math.ceil(response?.allTrashCatCount / limit));
    if (currentPage > Math.ceil(response?.allTrashCatCount / limit)) {
      setCurrentPage(Math.ceil(response?.allTrashCatCount / limit));
    }
    const data = await response?.data;
    setTrashedCategory(data);
    setLoading(false);
  };

  useEffect(() => {
    trashedCategoryData();
  }, [api, categoryRestored, categoryDeleted]);

  useEffect(() => {
    setApi(
      `${baseUrl}/apireact/alltrashcategory?limit=${limit}&offset=${offset}&userId=${userID}&isAdmin=${isAdmin}${global_search}`
    );
  }, [currentPage]);

  useEffect(() => {
    const debounceFn = setTimeout(() => {
      setCurrentPage(0);
      setApi(
        `${baseUrl}/apireact/alltrashcategory?limit=20&offset=0&userId=${userID}&isAdmin=${isAdmin}${global_search}`
      );
    }, 1000);

    return () => clearTimeout(debounceFn);
  }, [globalSearch]);

  const onGlobalSearchChange = (e) => {
    setGlobalSearch(e.target.value);
  };

  const handlePageClick = ({ selected: selectedPage }) => {
    setCurrentPage(selectedPage);
  };

  const setCategoryRestoredToTrue = () => {
    setCategoryRestored((count) => count + 1);
  };

  const setCategoryDeletedToTrue = () => {
    setCategoryDeleted((count) => count + 1);
  };

  return (
    <div className="trash-container">
      <div className="navbar">
        <Navbar />
      </div>
      <div className="trash-category-content">
        <div className="trash-category-table-header">
          <TextField
            name="globalSearch"
            label="Search"
            type="text"
            autoComplete="off"
            value={globalSearch}
            onChange={onGlobalSearchChange}
            variant="outlined"
            InputProps={{
              endAdornment: <AiOutlineSearch />,
            }}
          />
        </div>
        {!loading ? (
          trashedCategory.length > 0 ? (
            <div>
              <Table
                loading={loading}
                setCategoryRestoredToTrue={setCategoryRestoredToTrue}
                setCategoryDeletedToTrue={setCategoryDeletedToTrue}
                trashedCategory={trashedCategory}
              />
              <div className="trash-category-table-pagination">
                <ReactPaginate
                  previousLabel={<MdOutlineArrowBackIos />}
                  nextLabel={<MdOutlineArrowForwardIos />}
                  pageCount={pageCount}
                  onPageChange={handlePageClick}
                  forcePage={currentPage}
                  containerClassName={"trash-pagination"}
                  previousLinkClassName={"trash-pagination-link"}
                  nextLinkClassName={"trash-pagination-link"}
                  disabledClassName={"trash-pagination-link-disabled"}
                  activeClassName={"trash-pagination-link-active"}
                />
              </div>
            </div>
          ) : (
            <h5 className="no-trash-category-data">No Data Available</h5>
          )
        ) : (
          <Loading />
        )}
      </div>
    </div>
  );
};

export default TrashCategory;

import React, { useContext, useState, useEffect } from "react";
import { format } from "date-fns";
import { MdKeyboardArrowUp, MdKeyboardArrowDown } from "react-icons/md";
import pdfVersion from "../../api/pdfVersion";
import { AuthContext } from "../../context/Auth/AuthContext";
import Loading from "../material-ui/Loading";

const PdfVersionContent = ({ pdfVersionData }) => {
  const [isContentOpen, setIsContentOpen] = useState(false);
  const toggleContentVisibility = () => setIsContentOpen(!isContentOpen);

  return (
    <div className="document-version-data">
      <div
        className="document-version-basic-data"
        onClick={toggleContentVisibility}
      >
        <div>
          {pdfVersionData?.document_version === null
            ? "NULL"
            : `V${pdfVersionData?.document_version}`}
        </div>
        <div>{pdfVersionData?.created_by || "N/A"}</div>
        <div>
          {format(new Date(pdfVersionData?.date_entered), "MM-dd-yyyy") ||
            "N/A"}
        </div>
        <div className="dropdown-icon">
          {isContentOpen ? <MdKeyboardArrowUp /> : <MdKeyboardArrowDown />}
        </div>
      </div>
      {isContentOpen ? (
        <div className="document-version-detail-data">
          <div>
            <span className="document-version-detail-data-part">
              Document Type
            </span>
            <span className="document-version-detail-data-value">
              {"pdf" || "N/A"}
            </span>
          </div>
          <div>
            <span className="document-version-detail-data-part">
              Created By
            </span>
            <span className="document-version-detail-data-value">
              {pdfVersionData.created_by || "N/A"}
            </span>
          </div>
          <div>
            <span className="document-version-detail-data-part">
              Modified By
            </span>
            <span className="document-version-detail-data-value">
              {pdfVersionData?.modified_by || "N/A"}
            </span>
          </div>
          <div>
            <span className="document-version-detail-data-part">
              Modified At
            </span>
            <span className="document-version-detail-data-value">
              {format(new Date(pdfVersionData?.date_modified), "MM-dd-yyyy") ||
                "N/A"}
            </span>
          </div>
        </div>
      ) : null}
    </div>
  );
};

const PdfVersion = ({ documentID }) => {
  const [loading, setLoading] = useState(true);
  const [isOpen, setIsOpen] = useState(false);
  const [pdfVersionData, setPdfVersionData] = useState(false);

  const { token } = useContext(AuthContext);

  const togglePdfVersionVisibility = () => setIsOpen(!isOpen);

  const pdfVersionResponseFunc = async () => {
    setLoading(true);
    const response = await pdfVersion(token, documentID);
    const data = await response?.data;
    setPdfVersionData(data);
    setLoading(false);
  };

  useEffect(() => {
    pdfVersionResponseFunc();
  }, []);

  return (
    <div className="document-version-container">
      <div
        className="document-version-header"
        onClick={togglePdfVersionVisibility}
      >
        <div className="document-version-title">Document Version</div>
        <div className="document-version-visibility-icon">
          {isOpen ? <MdKeyboardArrowUp /> : <MdKeyboardArrowDown />}
        </div>
      </div>
      <div>
        {isOpen ? (
          !loading ? (
            <div className="document-version-content">
              {pdfVersionData?.map((pdfVersionData, index) => (
                <PdfVersionContent
                  key={index}
                  pdfVersionData={pdfVersionData}
                />
              ))}
            </div>
          ) : (
            <div className="document-details-loader">Loading Data</div>
          )
        ) : null}
      </div>
    </div>
  );
};

export default PdfVersion;

import baseUrl from "../config/baseUrl";

const restoreUser = async ({ token, id }) => {
  const formData = new FormData();
  formData.append("id", id);

  try {
    const response = await fetch(`${baseUrl}/apireact/bulk_restore_user`, {
      method: "POST",
      headers: {
        Accept: "application/json",
        Authorization: `Bearer ${token}`,
      },
      body: formData,
    });
    const data = await response.json();
    return data;
  } catch (error) {
    return error;
  }
};

export default restoreUser;

import React, { useContext, useState, useEffect } from "react";
import { toast } from "react-toastify";
import InputLabel from "@mui/material/InputLabel";
import MenuItem from "@mui/material/MenuItem";
import FormControl from "@mui/material/FormControl";
import TextField from "@mui/material/TextField";
import Select from "@mui/material/Select";
import { RiCloseCircleFill } from "react-icons/ri";
import AllDocumentsTable from "./AllDocumentsTable";
import { AuthContext } from "../../../context/Auth/AuthContext";
import getAllDocumentList from "../../../api/getAllDocumentList";
import UploadDocumentsPopup from "./UploadDocumentsPopup";
import Loading from "../../material-ui/Loading";
import BasicDatePicker from "../../material-ui/DatePicker";
import ButtonUI from "../../material-ui/ButtonUI";
import editAllDocs from "../../../api/editAllDocs";

// Category, Name, CaseId, Modified By (auto-set), Modified Date (auto-set), Description/Comment

const AllDocumentsList = ({
  caseNo,
  caseID,
  oppId,
  caseDocumentsType,
  selectedCaseDocumentsType,
  changeSelectedCaseDocumentsType,
  preferredLanguage,
}) => {
  const [loading, setLoading] = useState(true);
  const [documentDeleted, setDocumentDeleted] = useState(0);
  const [documentViewer, setDocumentViewer] = useState(false);
  const [pdfLink, setPdfLink] = useState("");
  const [pdfViewerTitle, setPdfViewerTitle] = useState("");

  const [category, setCategory] = useState("");
  const [name, setName] = useState("");
  const [docCaseID, setDocCaseID] = useState("");
  const [comment, setComment] = useState("");

  const [documentResponse, setDocumentResponse] = useState([]);
  const [categoryList, setCategoryList] = useState([]);

  const { token, userID } = useContext(AuthContext);

  const setDocumentDeletedToTrue = () => {
    setDocumentDeleted((count) => count + 1);
  };

  const changeDocumentViewerState = (id, link, name, category, description) => {
    setPdfLink(link);
    setName(name);
    setDocCaseID(id);
    setComment(description);
    setCategory(category);
    setPdfViewerTitle(name);
    setDocumentViewer(!documentViewer);
  };

  const onEditDocumentFormSubmit = async () => {
    const editDocumentFormResponse = await editAllDocs({
      token: token,
      user_id: userID,
      id: docCaseID,
      case_number: caseNo,
      document_title: name,
      document_desc: comment,
      category: category,
    });

    if (editDocumentFormResponse.code === 200) {
      setDocumentDeletedToTrue();
      setDocumentViewer(false);

      toast.success("Document is updated successfully", {
        position: "top-center",
        autoClose: 3000,
        theme: "dark",
        hideProgressBar: false,
        closeOnClick: true,
        pauseOnHover: true,
        draggable: true,
        progress: undefined,
      });
    }
  };

  const documentResponseFunc = async () => {
    if (!documentDeleted) {
      setLoading(true);
    }
    const data = await getAllDocumentList({ token, caseNo, caseID });
    setDocumentResponse(data?.data?.length !== 0 ? data?.data : []);
    setCategoryList(data?.category);
    if (!documentDeleted) {
      setLoading(false);
    }
  };

  useEffect(() => {
    documentResponseFunc();
  }, [documentDeleted]);

  return (
    <>
      {!documentViewer ? (
        <>
          <div className="all-doc-table-header">
            <UploadDocumentsPopup
              caseNo={caseNo}
              caseID={caseID}
              setDocumentDeletedToTrue={setDocumentDeletedToTrue}
            />
            <FormControl className="case-doc-select-ui">
              <InputLabel className="case-doc-select-label" id="select-label">
                Category
              </InputLabel>
              <Select
                name="addDocCategories"
                sx={{ margin: "10px 0px", maxWidth: "20" }}
                labelId="select-label"
                variant="outlined"
                id="simple-select"
                value={selectedCaseDocumentsType}
                onChange={changeSelectedCaseDocumentsType}
                label="Category"
              >
                {caseDocumentsType.map((option) => (
                  <MenuItem key={option} value={option}>
                    {option}
                  </MenuItem>
                ))}
              </Select>
            </FormControl>
          </div>
          {!loading ? (
            documentResponse.length !== 0 ? (
              <div className="document__table">
                <AllDocumentsTable
                  setDocumentDeletedToTrue={setDocumentDeletedToTrue}
                  oppId={oppId}
                  documentResponse={documentResponse}
                  preferredLanguage={preferredLanguage}
                  changeDocumentViewerState={changeDocumentViewerState}
                />
              </div>
            ) : (
              <h5 className="no-doc-data">No Data Available</h5>
            )
          ) : (
            <Loading />
          )}
        </>
      ) : (
        <div className="all-document-pdf-viewer">
          <div className="all-document-pdf-viewer-header">
            <div className="all-document-pdf-viewer-title">
              {pdfViewerTitle}
            </div>
            <div
              className="all-document-pdf-viewer-icon"
              onClick={() => setDocumentViewer(false)}
            >
              <RiCloseCircleFill />
            </div>
          </div>
          <div className="document-viewer-with-from">
            <div className="iframe-wrapper">
              <iframe
                title={pdfViewerTitle}
                src={pdfLink}
                width="100%"
                height="590"
              ></iframe>
            </div>
            <div className="document-viewer-form">
              <TextField
                name="name"
                label="Name"
                type="text"
                autoComplete="off"
                value={name}
                onChange={(e) => setName(e.target.value)}
                style={{ marginTop: "5px" }}
                variant="outlined"
                required
                fullWidth
              />
              <TextField
                name="caseId"
                label="Case ID"
                disabled="true"
                type="text"
                autoComplete="off"
                value={caseNo}
                variant="outlined"
                style={{ marginTop: "20px" }}
                required
                fullWidth
              />
              <FormControl fullWidth required>
                <InputLabel id="select-label" style={{ marginTop: "20px" }}>
                  Category
                </InputLabel>
                <Select
                  name="category"
                  sx={{ marginTop: "20px" }}
                  labelId="select-label"
                  variant="outlined"
                  id="simple-select"
                  value={category}
                  onChange={(e) => setCategory(e.target.value)}
                  label="category"
                >
                  {categoryList.map((option) => (
                    <MenuItem key={option} value={option}>
                      {option}
                    </MenuItem>
                  ))}
                </Select>
              </FormControl>
              <TextField
                name="comment"
                label="Comment"
                multiline
                minRows={3}
                maxRows={3}
                value={comment}
                onChange={(e) => setComment(e.target.value)}
                variant="outlined"
                style={{ marginTop: "20px" }}
                fullWidth
              />
              <ButtonUI
                formButton={false}
                loading={loading}
                variant="contained"
                buttonText="Submit"
                style={{
                  width: "150px",
                  height: "35px",
                  boxShadow: "none",
                  color: "#fff",
                  fontWeight: "500",
                  borderRadius: "4px",
                  letterSpacing: "0.07rem",
                  marginTop: "15px",
                }}
                disabled={
                  docCaseID === "" || name === "" || category === ""
                    ? true
                    : false
                }
                onClickFunc={onEditDocumentFormSubmit}
              />
            </div>
          </div>
        </div>
      )}
    </>
  );
};

export default AllDocumentsList;

import React, { useMemo, useContext } from "react";
import { format } from "date-fns";
import { useTable, useRowSelect } from "react-table";
import { toast } from "react-toastify";
import { Columns } from "./Columns";
import EditDocumentPopup from "./EditDocumentPopup";
import DeleteDocumentPopup from "./DeleteDocumentPopup";
import Loading from "../material-ui/Loading";
import CheckBox from "./CheckBox";
import { fileIcon } from "../common/FileIcons";
import Dropdown from "./Dropdown";
import { AuthContext } from "../../context/Auth/AuthContext";
import markDocumentsAsFavourite from "../../api/addBulkFavouriteDocument";
import removeDocumentsFromFavourite from "../../api/removeBulkFavouriteDocuments";
import markDocumentAsFavourite from "../../api/addFavouriteDocument";
import removeDocumentFromFavourite from "../../api/removeFavouriteDocument";
import "../../styles/Table.css";

const Table = ({
  loading,
  openFilter,
  addDocumentTab,
  changeDocumentFavouriteStateFunc,
  setDocumentUpdatedToTrue,
  setDocumentDeletedToTrue,
  allFavourite,
  documentTableData,
  categoryOptions,
}) => {
  const columns = useMemo(() => Columns, []);
  const data = useMemo(
    () =>
      documentTableData.map((table) => {
        return table;
      }),
    [documentTableData]
  );

  const { token } = useContext(AuthContext);

  const onStarredHeaderCheck = async (e) => {
    let docsID = documentTableData.map((doc) => {
      return `'${doc.id}'`;
    });

    if (e.target.checked) {
      const markDocumentsAsFavouriteResponse = await markDocumentsAsFavourite({
        token: token,
        id: docsID,
      });

      if (markDocumentsAsFavouriteResponse.code === 200) {
        changeDocumentFavouriteStateFunc();
        toast.success("Documents are added to favourite list", {
          position: "top-center",
          autoClose: 3000,
          theme: "dark",
          hideProgressBar: false,
          closeOnClick: true,
          pauseOnHover: true,
          draggable: true,
          progress: undefined,
        });
      }
    } else {
      const removeDocumentsFromFavouriteResponse =
        await removeDocumentsFromFavourite({
          token: token,
          id: docsID,
        });

      if (removeDocumentsFromFavouriteResponse.code === 200) {
        changeDocumentFavouriteStateFunc();
        toast.success("Documents are removed from favourite list", {
          position: "top-center",
          autoClose: 3000,
          theme: "dark",
          hideProgressBar: false,
          closeOnClick: true,
          pauseOnHover: true,
          draggable: true,
          progress: undefined,
        });
      }
    }
  };

  const onDocumentCheck = async (e, row) => {
    if (row.original.favourite === "0") {
      const markDocumentAsFavouriteResponse = await markDocumentAsFavourite({
        token: token,
        id: row.original.id,
      });

      if (markDocumentAsFavouriteResponse.code === 200) {
        changeDocumentFavouriteStateFunc();
        toast.success("Document is added to favourite list", {
          position: "top-center",
          autoClose: 3000,
          theme: "dark",
          hideProgressBar: false,
          closeOnClick: true,
          pauseOnHover: true,
          draggable: true,
          progress: undefined,
        });
      }
    }

    if (row.original.favourite === "1") {
      const removeDocumentFromFavouriteResponse =
        await removeDocumentFromFavourite({
          token: token,
          id: row.original.id,
        });

      if (removeDocumentFromFavouriteResponse.code === 200) {
        changeDocumentFavouriteStateFunc();
        toast.success("Document is removed from favourite list", {
          position: "top-center",
          autoClose: 3000,
          theme: "dark",
          hideProgressBar: false,
          closeOnClick: true,
          pauseOnHover: true,
          draggable: true,
          progress: undefined,
        });
      }
    }
  };

  const tableHooks = (hooks) => {
    hooks.visibleColumns.push((columns) => [
      ...columns,
      {
        Header: "Action",
        Cell: ({ row }) => (
          <>
            <EditDocumentPopup
              setDocumentUpdatedToTrue={setDocumentUpdatedToTrue}
              tableValues={row.values}
              categoryOptions={categoryOptions}
            />
            <DeleteDocumentPopup
              setDocumentDeletedToTrue={setDocumentDeletedToTrue}
              id={row.values.id}
            />
          </>
        ),
      },
    ]);
  };

  const {
    getTableProps,
    getTableBodyProps,
    headerGroups,
    rows,
    prepareRow,
    selectedFlatRows,
    getToggleAllRowsSelectedProps,
  } = useTable(
    {
      columns: columns,
      data: data,
      initialState: {
        hiddenColumns: [
          "id",
          "favourite",
          "document_desc",
          "case_cat_type",
          "bg_color",
          "name",
        ],
      },
    },
    tableHooks,
    useRowSelect,
    (hooks) => {
      hooks.visibleColumns.push((columns) => {
        return [
          {
            id: "selection",
            Cell: ({ row }) => (
              <>
                <CheckBox {...row.getToggleRowSelectedProps()} />
              </>
            ),
          },
          {
            id: "starred",
            Cell: ({ row }) => (
              <>
                <input
                  className="star-checkbox"
                  type="checkbox"
                  checked={row.original.favourite === "1" ? true : false}
                  onChange={(e) => onDocumentCheck(e, row)}
                />
              </>
            ),
          },
          ...columns,
        ];
      });
    }
  );

  const selectedFlatRowsID = selectedFlatRows.map(
    (row) => `'${row.values.id}'`
  );
  const documentTableDataID = documentTableData.map((row) => "tableData");

  const isEntireTableRowsSelected =
    selectedFlatRowsID.length === documentTableDataID.length;

  const SelectionHeader = ({ getToggleAllRowsSelectedProps }) => {
    return (
      <div className="bulk-func">
        <CheckBox {...getToggleAllRowsSelectedProps()} />
        <Dropdown
          selectedRows={selectedFlatRowsID}
          isEntireTableRowsSelected={isEntireTableRowsSelected}
          setDocumentDeletedToTrue={setDocumentDeletedToTrue}
        />
      </div>
    );
  };

  const StarredHeader = () => {
    return (
      <input
        type="checkbox"
        className="star-checkbox"
        checked={allFavourite}
        onChange={onStarredHeaderCheck}
      />
    );
  };

  return (
    <div className={!openFilter ? "table-content" : "resized-table-content"}>
      {!loading ? (
        <table className="documents-table" {...getTableProps()}>
          <thead className="documents-table-header">
            {headerGroups.map((headerGroup, index) => (
              <tr
                className="documents-table-header-row"
                key={index}
                {...headerGroup.getHeaderGroupProps()}
              >
                {headerGroup.headers.map((column, index) => {
                  return (
                    <th className="documents-table-header-data" key={index}>
                      <span className="document-table-select-row">
                        {column.id == "selection" ? (
                          <SelectionHeader
                            getToggleAllRowsSelectedProps={
                              getToggleAllRowsSelectedProps
                            }
                          />
                        ) : column.id == "starred" ? (
                          <StarredHeader />
                        ) : (
                          column.render("Header")
                        )}
                      </span>
                    </th>
                  );
                })}
              </tr>
            ))}
          </thead>
          <tbody className="documents-table-body" {...getTableBodyProps()}>
            {rows.map((row, index) => {
              prepareRow(row);
              return (
                <tr
                  key={index}
                  className="document-table-body-row"
                  {...row.getRowProps()}
                >
                  {row.cells.map((cell, index) => {
                    switch (cell.column.Header) {
                      case "File":
                        return (
                          <td key={index} className="file-icon">
                            <span
                              style={{
                                cursor: "pointer",
                              }}
                              onClick={() =>
                                cell.row.values.document_type === "pdf" ||
                                cell.row.values.document_type === "jpg" ||
                                cell.row.values.document_type === "jpeg" ||
                                cell.row.values.document_type === "png"
                                  ? addDocumentTab(cell.row.values)
                                  : null
                              }
                            >
                              {fileIcon[cell.value]}
                            </span>
                          </td>
                        );
                      case "Name":
                        return (
                          <td key={index}>
                            <span
                              style={{
                                cursor: "pointer",
                              }}
                              onClick={() =>
                                cell.row.values.document_type === "pdf" ||
                                cell.row.values.document_type === "jpg" ||
                                cell.row.values.document_type === "jpeg" ||
                                cell.row.values.document_type === "png"
                                  ? addDocumentTab(cell.row.values)
                                  : null
                              }
                            >
                              {cell.value}
                            </span>
                          </td>
                        );
                      case "Category":
                        return (
                          <td key={index}>
                            <p
                              className="category"
                              style={{
                                background: `${
                                  cell.row.values.bg_color ||
                                  "rgba(153, 102, 255, 0.2)"
                                }`,
                              }}
                            >
                              {cell.row.values.badge || "Other"}
                            </p>
                          </td>
                        );
                      case "Uploaded":
                        return (
                          <td key={index}>
                            {cell.row.values.created_at !== "0000-00-00" &&
                            cell.row.values.created_at !== "0000-00-00 00:00:00"
                              ? format(
                                  new Date(cell.row.values.created_at),
                                  "MM-dd-yyyy"
                                )
                              : "N/A"}
                          </td>
                        );

                      default:
                        return (
                          <td key={index} {...cell.getCellProps()}>
                            {cell.render("Cell")}
                          </td>
                        );
                    }
                  })}
                </tr>
              );
            })}
          </tbody>
        </table>
      ) : (
        <Loading />
      )}
    </div>
  );
};

export default Table;

import React, { useState, useContext, useEffect } from "react";
import TextField from "@mui/material/TextField";
import InputLabel from "@mui/material/InputLabel";
import MenuItem from "@mui/material/MenuItem";
import FormControl from "@mui/material/FormControl";
import Select from "@mui/material/Select";
import { toast } from "react-toastify";
import { AiOutlineCloudUpload } from "react-icons/ai";
import { RiCloseCircleFill } from "react-icons/ri";
import PropTypes from "prop-types";
import { styled } from "@mui/material/styles";
import Dialog from "@mui/material/Dialog";
import DialogTitle from "@mui/material/DialogTitle";
import DialogContent from "@mui/material/DialogContent";
import DialogActions from "@mui/material/DialogActions";
import IconButton from "@mui/material/IconButton";
import ButtonUI from "../../material-ui/ButtonUI";
import { AuthContext } from "../../../context/Auth/AuthContext";
import requestedDocumentPopup from "../../../api/requestDocumentPopup";
import "../../../styles/RequestDocumentsPopup.css";
import saveRequestedDocument from "../../../api/saveRequestedDocument";

const BootstrapDialog = styled(Dialog)(({ theme }) => ({
  "& .MuiDialogContent-root": {
    padding: theme.spacing(2),
  },
  "& .MuiDialogActions-root": {
    padding: theme.spacing(1),
  },
}));

const BootstrapDialogTitle = (props) => {
  const { children, onClose, ...other } = props;

  return (
    <DialogTitle sx={{ m: 0, p: 2 }} {...other}>
      {children}
      {onClose ? (
        <IconButton
          aria-label="close"
          onClick={onClose}
          sx={{
            position: "absolute",
            right: 8,
            top: 8,
            color: (theme) => theme.palette.grey[500],
          }}
        >
          <RiCloseCircleFill />
        </IconButton>
      ) : null}
    </DialogTitle>
  );
};

BootstrapDialogTitle.propTypes = {
  children: PropTypes.node,
  onClose: PropTypes.func.isRequired,
};

const RequestDocumentsPopup = ({
  caseID,
  contactID,
  status,
  documentTypeArray,
  setCategoryUpdatedToTrue,
  serviceType,
  serviceSubType,
}) => {
  const [open, setOpen] = useState(false);
  const [loading, setLoading] = useState(false);
  const [tags, setTags] = useState([]);
  const [selectedDocumentRequestedStage, setSelectedDocumentRequestedStage] =
    useState("Stage 1");
  const [requestDocList, setRequestDocList] = useState({});
  const [addBenificiary, setAddBenificiary] = useState("");

  const { token, userID } = useContext(AuthContext);

  const onRequestDocumentInPopupFunc = async () => {
    setLoading(true);
    const onRequestDocumentInPopupResponse = await requestedDocumentPopup({
      token: token,
      stage: selectedDocumentRequestedStage,
      serviceType: serviceType,
      serviceSubType: serviceSubType,
      caseID: caseID,
    });

    const requiredTagsData =
      onRequestDocumentInPopupResponse?.data?.length === 0
        ? []
        : onRequestDocumentInPopupResponse?.data?.map((doc) => {
            return {
              caseId: caseID,
              contactId: contactID,
              name: doc.name,
              userId: userID,
              documentCategory: "Required",
              documentType: doc.service_type_c,
              status: "Requested",
            };
          });

    setRequestDocList(onRequestDocumentInPopupResponse?.data);
    setTags(requiredTagsData);
    setLoading(false);
  };

  useEffect(() => {
    setLoading(true);
    setAddBenificiary("");
    setSelectedDocumentRequestedStage("Stage 1");
    if (open === true) {
      onRequestDocumentInPopupFunc();
      setLoading(false);
    }
  }, [open]);

  useEffect(() => {
    onRequestDocumentInPopupFunc();
  }, [selectedDocumentRequestedStage]);

  const requestedDocumentStageArray = ["Stage 1", "Stage 2", "Stage 3"];

  const handleKeyDown = (e) => {
    if (e.key !== "Enter") return;
    const value = e.target.value;
    if (!value.trim()) return;
    setTags([
      ...tags,
      {
        caseId: caseID,
        contactId: contactID,
        name: value,
        userId: userID,
        documentCategory: "Recommended",
        documentType: "Others",
        status: "Requested",
      },
    ]);
    e.target.value = "";
  };

  const removeTag = (index) => {
    const filteredTags = tags.filter((tag, i) => i !== index);
    setTags(filteredTags);
  };

  const handleClickOpen = () => {
    setOpen(true);
  };

  const handleClose = () => {
    setOpen(false);
  };

  const onAddBenificiaryChange = (e) => {
    setAddBenificiary(e.target.value);
  };

  const onRequestDocumentFormSubmit = async () => {
    let updatedTags =
      addBenificiary.length > 0
        ? tags.map((tag) => {
            return {
              ...tag,
              name: `${addBenificiary}_${tag.name}`,
            };
          })
        : tags;

    const requestDocument = await saveRequestedDocument({
      token: token,
      tags: updatedTags,
    });

    if (requestDocument.code === 200) {
      setOpen(false);
      setCategoryUpdatedToTrue();

      toast.success("Requested Documents are saved successfully", {
        position: "top-center",
        autoClose: 3000,
        theme: "dark",
        hideProgressBar: false,
        closeOnClick: true,
        pauseOnHover: true,
        draggable: true,
        progress: undefined,
      });
    }
  };

  return (
    <div className="dialog-modal">
      <ButtonUI
        formButton={false}
        variant="contained"
        buttonText="Request Docs"
        style={{
          width: "135px",
          height: "35px",
          color: "#000",
          marginRight: "10px",
          backgroundColor: "#2b93c8d0",
          boxShadow: "none",
          fontWeight: "200",
          borderRadius: "4px",
          letterSpacing: "0.07rem",
        }}
        onClickFunc={handleClickOpen}
      ></ButtonUI>
      <BootstrapDialog
        onClose={handleClose}
        aria-labelledby="customized-dialog-title"
        open={open}
      >
        <BootstrapDialogTitle
          id="customized-dialog-title"
          className="edit-doc-modal"
          onClose={handleClose}
        >
          <div className="popup-header-svg-icon">
            <AiOutlineCloudUpload />
          </div>
          <div className="popup-header-title">Request Document</div>
        </BootstrapDialogTitle>
        <DialogContent dividers>
          <div className="request-doc-top-section">
            <FormControl className="request-doc-select-ui">
              <InputLabel
                className="request-doc-select-label"
                id="select-label"
              >
                Stage
              </InputLabel>
              <Select
                name="addDocCategories"
                sx={{ margin: "10px 0px", maxWidth: "20" }}
                labelId="select-label"
                variant="outlined"
                id="simple-select"
                value={selectedDocumentRequestedStage}
                onChange={(e) =>
                  setSelectedDocumentRequestedStage(e.target.value)
                }
                label="Category"
              >
                {requestedDocumentStageArray.map((option) => (
                  <MenuItem key={option} value={option}>
                    {option}
                  </MenuItem>
                ))}
              </Select>
            </FormControl>
            <TextField
              name="addBenificiary"
              label="Add Beneficiary"
              type="text"
              autoComplete="off"
              value={addBenificiary}
              onChange={onAddBenificiaryChange}
              className="requested-doc-textfield"
              variant="outlined"
            />
          </div>
          <div className="tags-input-container">
            <div className="tags-input-content">
              {!loading ? (
                <>
                  {tags?.map((tag, index) => (
                    <div className="tag-item">
                      <span className="text">{tag?.name}</span>
                      <span className="close" onClick={() => removeTag(index)}>
                        <RiCloseCircleFill />
                      </span>
                    </div>
                  ))}
                  <input
                    onKeyDown={handleKeyDown}
                    placeholder="Type here ..."
                    type="text"
                    className="tags-input"
                  />
                </>
              ) : (
                "Loading Document List ..."
              )}
            </div>
          </div>
        </DialogContent>
        <DialogActions>
          <div className="popup_submit_btn">
            <ButtonUI
              formButton={false}
              variant="contained"
              buttonText="Submit"
              disabled={tags?.length > 0 ? false : true}
              autoFocus={true}
              onClickFunc={onRequestDocumentFormSubmit}
            />
          </div>
        </DialogActions>
      </BootstrapDialog>
    </div>
  );
};

export default RequestDocumentsPopup;

export const Columns = [
  {
    Header: "ID",
    accessor: "id",
  },
  {
    Header: "Case ID",
    accessor: "case_number",
  },
  {
    Header: "File",
    accessor: "document_type",
  },
  {
    Header: "Name",
    accessor: "document_title",
  },
  {
    Header: "Category",
    accessor: "badge",
  },
  {
    Header: "Category Color",
    accessor: "bg_color",
  },

  {
    Header: "Uploaded",
    accessor: "date_entered",
  },
];

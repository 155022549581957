import React, { useMemo } from "react";
import { format } from "date-fns";
import { useTable, useSortBy, useRowSelect } from "react-table";
import RestoreUser from "./RestoreUser";
import PermanentlyDeleteUser from "./PermanentlyDeleteUser";
import { Columns } from "./Column";
import CheckBox from "./CheckBox";
import Dropdown from "./Dropdown";
import Loading from "../../material-ui/Loading";
import "../../../styles/Table.css";

const Table = ({
  loading,
  setUserRestoredToTrue,
  setUserDeletedToTrue,
  trashedUser,
}) => {
  const columns = useMemo(() => Columns, []);
  const data = useMemo(() => trashedUser, [trashedUser]);

  const tableHooks = (hooks) => {
    hooks.visibleColumns.push((columns) => [
      ...columns,
      {
        Header: "Action",
        Cell: ({ row }) => (
          <>
            <RestoreUser
              id={row.values.id}
              setUserRestoredToTrue={setUserRestoredToTrue}
            />
            <PermanentlyDeleteUser
              id={row.values.id}
              setUserDeletedToTrue={setUserDeletedToTrue}
            />
          </>
        ),
      },
    ]);
  };

  const {
    getTableProps,
    getTableBodyProps,
    headerGroups,
    rows,
    prepareRow,
    selectedFlatRows,
    getToggleAllRowsSelectedProps,
  } = useTable(
    {
      columns: columns,
      data: data,
      initialState: {
        hiddenColumns: ["id", "first_name", "last_name"],
      },
    },
    tableHooks,
    useSortBy,
    useRowSelect,
    (hooks) => {
      hooks.visibleColumns.push((columns) => {
        return [
          {
            id: "selection",
            Cell: ({ row }) => (
              <>
                <CheckBox {...row.getToggleRowSelectedProps()} />
              </>
            ),
          },
          ...columns,
        ];
      });
    }
  );

  const selectedFlatRowsID = selectedFlatRows.map(
    (row) => `'${row.values.id}'`
  );
  const documentTableDataID = trashedUser.map((row) => "tableData");

  const isEntireTableRowsSelected =
    selectedFlatRowsID.length === documentTableDataID.length;

  const SelectionHeader = ({ getToggleAllRowsSelectedProps }) => {
    return (
      <div className="bulk-func">
        <CheckBox {...getToggleAllRowsSelectedProps()} />
        <Dropdown
          selectedRows={selectedFlatRowsID}
          isEntireTableRowsSelected={isEntireTableRowsSelected}
          setUserRestoredToTrue={setUserRestoredToTrue}
          setUserDeletedToTrue={setUserDeletedToTrue}
        />
      </div>
    );
  };

  return (
    <div className="trash-doc-table-content table-content">
      {!loading ? (
        <table
          className="documents-table deleted-users-tables"
          {...getTableProps()}
        >
          <thead className="documents-table-header">
            {headerGroups.map((headerGroup, index) => (
              <tr
                key={index}
                {...headerGroup.getHeaderGroupProps()}
                className="documents-table-header-row"
              >
                {headerGroup.headers.map((column, index) => {
                  return (
                    <th
                      key={index}
                      className="documents-table-header-data"
                      {...column.getHeaderProps()}
                    >
                      {column.id == "selection" ? (
                        <SelectionHeader
                          getToggleAllRowsSelectedProps={
                            getToggleAllRowsSelectedProps
                          }
                        />
                      ) : (
                        column.render("Header")
                      )}
                    </th>
                  );
                })}
              </tr>
            ))}
          </thead>
          <tbody className="documents-table-body" {...getTableBodyProps()}>
            {rows.map((row) => {
              prepareRow(row);
              return (
                <tr {...row.getRowProps()}>
                  {row.cells.map((cell, index) => {
                    if (cell.column.Header == "Name") {
                      return (
                        <td key={index} {...cell.getCellProps()}>
                          {`${cell.row.values.first_name} ${cell.row.values.last_name}`}
                        </td>
                      );
                    }

                    if (cell.column.Header == "Role") {
                      return (
                        <td key={index} {...cell.getCellProps()}>
                          {cell.value === "1" ? "Admin" : "Non Admin"}
                        </td>
                      );
                    }

                    if (cell.column.Header == "Email") {
                      return cell.value ? (
                        <td key={index} {...cell.getCellProps()}>
                          {cell.value}
                        </td>
                      ) : (
                        <td key={index} {...cell.getCellProps()}>
                          N/A
                        </td>
                      );
                    }

                    if (cell.column.Header == "Created At") {
                      return (
                        <td key={index}>
                          {cell.value !== "0000-00-00" &&
                          cell.value !== "0000-00-00 00:00:00" &&
                          cell.value !== null
                            ? format(new Date(cell.value), "MM-dd-yyyy HH:mm")
                            : "N/A"}
                        </td>
                      );
                    }
                    return (
                      <td {...cell.getCellProps()}>{cell.render("Cell")}</td>
                    );
                  })}
                </tr>
              );
            })}
          </tbody>
        </table>
      ) : (
        <Loading />
      )}
    </div>
  );
};

export default Table;

import React, { useMemo } from "react";
import { format } from "date-fns";
import { useTable, useSortBy } from "react-table";
import { IoMdArrowDropup, IoMdArrowDropdown } from "react-icons/io";
import { Columns } from "./UserColumns";
import StatusPopup from "./StatusPopup";
import EditUserPopup from "./EditUserPopup";
import DeleteUserPopup from "./DeleteUserPopup";
import Loading from "../material-ui/Loading";
import "../../styles/Table.css";

const UserTable = ({ loading, userTableData, setUserAddedToTrue }) => {
  const columns = useMemo(() => Columns, []);
  const data = useMemo(() => userTableData, [userTableData]);

  const tableHooks = (hooks) => {
    hooks.visibleColumns.push((columns) => [
      ...columns,
      {
        Header: "Action",
        Cell: ({ row }) => (
          <>
            <StatusPopup
              setUserAddedToTrue={setUserAddedToTrue}
              tableValues={row.values}
            />
            <EditUserPopup
              setUserAddedToTrue={setUserAddedToTrue}
              tableValues={row.values}
            />
            <DeleteUserPopup
              setUserAddedToTrue={setUserAddedToTrue}
              id={row.values.id}
            />
          </>
        ),
      },
    ]);
  };

  const { getTableProps, getTableBodyProps, headerGroups, rows, prepareRow } =
    useTable(
      {
        columns: columns,
        data: data,
        initialState: {
          hiddenColumns: ["id", "first_name", "last_name"],
        },
      },
      tableHooks,
      useSortBy
    );

  return (
    <div className="table-content">
      {!loading ? (
        <div>
          <table className="documents-table user-tables" {...getTableProps()}>
            <thead className="documents-table-header other-tables-header">
              {headerGroups.map((headerGroup) => (
                <tr {...headerGroup.getHeaderGroupProps()}>
                  {headerGroup.headers.map((column) => (
                    <th
                      {...column.getHeaderProps(column.getSortByToggleProps())}
                    >
                      <span className="document-table-select-row">
                        <span
                          style={{
                            display: "flex",
                            justifyContent: "center",
                            gap: "5px",
                          }}
                        >
                          {column.render("Header")}
                          <span
                            style={{
                              display: "flex",
                              justifyContent: "center",
                              gap: "5px",
                              fontSize: "16px",
                            }}
                          >
                            {column.isSorted ? (
                              column.isSortedDesc ? (
                                <IoMdArrowDropdown />
                              ) : (
                                <IoMdArrowDropup />
                              )
                            ) : (
                              ""
                            )}
                          </span>
                        </span>
                      </span>
                    </th>
                  ))}
                </tr>
              ))}
            </thead>
            <tbody
              className="documents-table-body other-tables-body"
              {...getTableBodyProps()}
            >
              {rows.map((row) => {
                prepareRow(row);
                return (
                  <tr {...row.getRowProps()}>
                    {row.cells.map((cell, index) => {
                      if (cell.column.Header == "Name") {
                        return (
                          <td key={index} {...cell.getCellProps()}>
                            {`${cell.row.values.first_name} ${cell.row.values.last_name}`}
                          </td>
                        );
                      }

                      if (cell.column.Header == "Role") {
                        return (
                          <td key={index} {...cell.getCellProps()}>
                            {cell.value === "1" ? "Admin" : "Non Admin"}
                          </td>
                        );
                      }

                      if (cell.column.Header == "Email") {
                        return cell.value ? (
                          <td key={index} {...cell.getCellProps()}>
                            {cell.value}
                          </td>
                        ) : (
                          <td key={index} {...cell.getCellProps()}>
                            N/A
                          </td>
                        );
                      }

                      if (cell.column.Header == "Created At") {
                        return (
                          <td key={index}>
                            {cell.value !== "0000-00-00" &&
                            cell.value !== "0000-00-00 00:00:00" &&
                            cell.value !== null
                              ? format(new Date(cell.value), "MM-dd-yyyy HH:mm")
                              : "N/A"}
                          </td>
                        );
                      }
                      return (
                        <td {...cell.getCellProps()}>{cell.render("Cell")}</td>
                      );
                    })}
                  </tr>
                );
              })}
            </tbody>
          </table>
        </div>
      ) : (
        <Loading />
      )}
    </div>
  );
};

export default UserTable;

import React, { useState, useContext, useEffect } from "react";
import { FaEye } from "react-icons/fa";
import { toast } from "react-toastify";
import { TiDeleteOutline } from "react-icons/ti";

import ButtonUI from "../../material-ui/ButtonUI";
import { AuthContext } from "../../../context/Auth/AuthContext";
import "../../../styles/LinkPopup.css";
import { caseListBaseUrl } from "../../../config/baseUrl";
import downloadLink from "../../../api/downloadLink";

const DocumentViewer = ({ tableValues, caseNo, changeDocumentViewerState }) => {
  const { token } = useContext(AuthContext);

  const openLinkInNewTab = async () => {
    const versionID = tableValues.download_link_c.slice(31);

    const downloadLinkFormResponse = await downloadLink({
      token: token,
      caseNo: caseNo,
      versionId: versionID.substring(0, versionID.length - 1),
      platform: "AWS",
    });

    if (downloadLinkFormResponse?.code === 200) {
      const link = downloadLinkFormResponse?.data;
      window.open(`${caseListBaseUrl}/${link}`);
    }
  };

  return (
    <div className="single-link-popup-container">
      <ButtonUI
        formButton={false}
        variant="outlined"
        buttonText={<FaEye />}
        style={{
          backgroundColor:
            tableValues.status_c === "Requested" ? "#d8d8e4df" : "#ffc107",
          color: tableValues.status_c === "Requested" ? "#ffc107" : "white",
          marginRight: "5px",
        }}
        disabled={tableValues.status_c === "Requested" ? true : false}
        onClickFunc={openLinkInNewTab}
      />
    </div>
  );
};

export default DocumentViewer;

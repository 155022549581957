import { createContext, useEffect, useReducer } from "react";
import AuthReducer from "./AuthReducer";

const INITIAL_STATE = {
  token: JSON.parse(localStorage.getItem("token")) || null,
  userID: JSON.parse(localStorage.getItem("userID")) || null,
  userEmail: JSON.parse(localStorage.getItem("userEmail")) || null,
  isAdmin: JSON.parse(localStorage.getItem("isAdmin")) || null,
  // phoneNumber: JSON.parse(localStorage.getItem("phoneNumber")) || null,
};

export const AuthContext = createContext(INITIAL_STATE);

export const AuthContextProvider = ({ children }) => {
  const [state, dispatch] = useReducer(AuthReducer, INITIAL_STATE);

  useEffect(() => {
    localStorage.setItem("token", JSON.stringify(state.token));
    localStorage.setItem("userID", JSON.stringify(state.userID));
    localStorage.setItem("userEmail", JSON.stringify(state.userEmail));
    localStorage.setItem("isAdmin", JSON.stringify(state.isAdmin));
    // localStorage.setItem("phoneNumber", JSON.stringify(state.phoneNumber));
  }, [state.token]);

  return (
    <AuthContext.Provider
      value={{
        token: state.token,
        userID: state.userID,
        userEmail: state.userEmail,
        isAdmin: state.isAdmin,
        // phoneNumber: state.phoneNumber,
        dispatch,
      }}
    >
      {children}
    </AuthContext.Provider>
  );
};

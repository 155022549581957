import baseUrl from "../config/baseUrl";

const editDocument = async ({
  token,
  id,
  user_id,
  case_number,
  document_title,
  document_desc,
  category,
}) => {
  try {
    const response = await fetch(`${baseUrl}/apireact/update_documents`, {
      method: "POST",
      headers: {
        Accept: "application/json",
        Authorization: `Bearer ${token}`,
      },
      body: new URLSearchParams({
        user_id: user_id,
        document_id: id,
        case_number: case_number,
        document_title: document_title,
        document_desc: document_desc,
        category: category,
      }),
    });
    const data = await response.json();
    return data;
  } catch (error) {
    return error;
  }
};

export default editDocument;

import React, { useState, useEffect, useContext } from "react";
import { Link } from "react-router-dom";
import { CgFileDocument } from "react-icons/cg";
import { FaUserCog } from "react-icons/fa";
import { BiCategory } from "react-icons/bi";
import Loading from "../components/material-ui/Loading";
import Navbar from "../components/common/Navbar";
import PieChart from "../components/charts/PieChart";
import BarChart from "../components/charts/BarChart";
import { AuthContext } from "../context/Auth/AuthContext";
import dashboardData from "../api/dashboard";
import documentChart from "../api/documentChart";
import "../styles/Dashboard.css";

const Dashboard = () => {
  const [loading, setLoading] = useState(true);

  const [dashboardResponse, setDashboardResponse] = useState({});
  const [documentUploadedResponse, setDocumentUploadedResponse] = useState([]);
  const { token, userID, isAdmin } = useContext(AuthContext);

  const dashboardAPIData = async () => {
    const dashboardResponseFunc = async () => {
      const response = await dashboardData({ token, userID, isAdmin });
      setDashboardResponse(response);
    };

    const documentUploadedResponseFunc = async () => {
      const response = await documentChart({ token, userID, isAdmin });
      const data = await response?.dashboardDocumentData;
      setDocumentUploadedResponse(data);
    };

    await dashboardResponseFunc();
    await documentUploadedResponseFunc();
    setLoading(false);
  };

  useEffect(() => {
    dashboardAPIData();
  }, []);

  return (
    <div className="dashboard-container">
      <div className="navbar">
        <Navbar />
      </div>
      {!loading ? (
        <div className="dashboard-page-content">
          <div className="dahboard-column">
            <div className="dashboard-data">
              <div className="box-part-one">
                <Link to="/users" className="dashboard-stats">
                  <div className="data-count">
                    {dashboardResponse?.userCount || 0}
                  </div>
                  <div className="data-with-icon">
                    <div>
                      <FaUserCog />
                    </div>
                    <div className="data">Users</div>
                  </div>
                </Link>
                <Link to="/category" className="dashboard-stats">
                  <div className="data-count">
                    {dashboardResponse?.categoryCount}
                  </div>
                  <div className="data-with-icon">
                    <div>
                      <BiCategory />
                    </div>
                    <div className="data">Categories</div>
                  </div>
                </Link>
              </div>
              <div className="box-part-two">
                <Link to="/documents" className="dashboard-stats">
                  <div className="data-count">
                    {dashboardResponse?.documentCount}
                  </div>
                  <div className="data-with-icon">
                    <div>
                      <CgFileDocument />
                    </div>
                    <div className="data">Documents</div>
                  </div>
                </Link>
              </div>
            </div>
            <div className="piechart">
              <PieChart
                logicdocument={dashboardResponse?.logicsdocumentsCount}
                MMSdocument={dashboardResponse?.mmsattachmentsCategoryCount}
                Manualdocument={dashboardResponse?.manualprocessCategoryCount}
                APIdocument={dashboardResponse?.mobileapiCategoryCount}
                Sugardocument={dashboardResponse?.sugarcrmCount}
              />
            </div>
          </div>
          <div className="dashboard-column-2">
            <BarChart documentUploadedResponse={documentUploadedResponse} />
          </div>
        </div>
      ) : (
        <Loading />
      )}
    </div>
  );
};

export default Dashboard;

import React, { useState, useEffect, useContext } from "react";
import ReactPaginate from "react-paginate";
import {
  MdOutlineArrowBackIos,
  MdOutlineArrowForwardIos,
} from "react-icons/md";
import { RiFilter2Fill } from "react-icons/ri";
import { AiOutlineSearch } from "react-icons/ai";
import Autocomplete from "@mui/material/Autocomplete";
import TextField from "@mui/material/TextField";
import documentData from "../../api/documents";
import { AuthContext } from "../../context/Auth/AuthContext";
import Table from "./Table";
import AddDocument from "./AddDocument";
import Loading from "../material-ui/Loading";
import ButtonUI from "../material-ui/ButtonUI";
import BasicDatePicker from "../material-ui/DatePicker";
import baseUrl from "../../config/baseUrl";
import "../../styles/DocumentTable.css";

const DocumentTable = ({
  addDocumentTab,
  showAddDocumentForm,
  onAddDocumentButtonClick,
}) => {
  const [loading, setLoading] = useState(false);
  const [currentPage, setCurrentPage] = useState(0);
  const [pageCount, setPageCount] = useState(0);
  const [openFilter, setOpenFilter] = useState(false);
  const [allFavourite, setAllFavourite] = useState(false);

  const [documentResponse, setDocumentResponse] = useState([]);

  const [categoryOptions, setCategoryOptions] = useState([]);
  const [fileTypeOptions, setFileTypeOptions] = useState([]);

  const [globalSearch, setGlobalSearch] = useState("");
  const [selectedCategoryOptions, setSelectedCategoryOptions] = useState(null);
  const [selectedFileTypeOptions, setSelectedFileTypeOptions] = useState(null);
  const [searchedUserID, setSearchedUserID] = useState("");
  const [searchedDate, setSearchedDate] = useState(null);

  const { token, userID, isAdmin } = useContext(AuthContext);

  const [api, setApi] = useState(
    `${baseUrl}/apireact/alldocument?limit=20&offset=0&userId=${userID}&isAdmin=${isAdmin}`
  );

  const [documentAdded, setDocumentAdded] = useState(0);
  const [documentUpdated, setDocumentUpdated] = useState(0);
  const [documentDeleted, setDocumentDeleted] = useState(0);
  const [documentFavouriteState, setDocumentFavouriteState] = useState(false);

  const limit = 20;
  const offset = currentPage * limit;

  let global_search = globalSearch ? `&global_search=${globalSearch}` : "";
  let document_type = selectedFileTypeOptions
    ? `&document_type=${selectedFileTypeOptions}`
    : "";
  let category_id = selectedCategoryOptions
    ? `&category=${selectedCategoryOptions}`
    : "";
  let created_at = searchedDate
    ? `&created_at=${searchedDate.toLocaleDateString("fr-CA")}`
    : "";
  let case_number = searchedUserID ? `&case_number=${searchedUserID}` : "";

  const documentResponseFunc = async () => {
    setLoading(true);
    const response = await documentData({ token, api });

    const notNullFileType = response.document_type.filter(
      (data) => data !== null
    );
    setPageCount(Math.ceil(response?.DocsCount / limit));
    setAllFavourite(response.allStarValue === "1" ? true : false);
    if (currentPage > Math.ceil(response?.DocsCount / limit)) {
      setCurrentPage(Math.ceil(response?.DocsCount / limit));
    }
    setDocumentResponse(response.data);
    setFileTypeOptions(notNullFileType);
    setCategoryOptions(response.category);
    setLoading(false);
  };

  useEffect(() => {
    setApi(
      `${baseUrl}/apireact/alldocument?limit=${limit}&offset=${offset}&userId=${userID}&isAdmin=${isAdmin}${global_search}${document_type}${category_id}${case_number}${created_at}`
    );
  }, [currentPage]);

  useEffect(() => {
    const debounceFn = setTimeout(() => {
      setCurrentPage(0);
      setApi(
        `${baseUrl}/apireact/alldocument?limit=${20}&offset=${0}&userId=${userID}&isAdmin=${isAdmin}${global_search}${document_type}${category_id}${case_number}${created_at}`
      );
    }, 2500);

    return () => clearTimeout(debounceFn);
  }, [globalSearch]);

  useEffect(() => {
    documentResponseFunc();
  }, [
    api,
    documentFavouriteState,
    documentAdded,
    documentUpdated,
    documentDeleted,
  ]);

  const onGlobalSearchChange = (e) => {
    setGlobalSearch(e.target.value);
  };

  const onCategoryOptionChange = (selectedCategory) => {
    setSelectedCategoryOptions(selectedCategory);
  };

  const onFileTypeOptionChange = (selectedFileType) => {
    setSelectedFileTypeOptions(selectedFileType);
  };

  const onSearchedUserIDChange = (e) => {
    setSearchedUserID(e.target.value);
  };

  const onSearchedDateChange = (newDate) => {
    setSearchedDate(newDate);
  };

  const setDocumentAddedToTrue = () => {
    setDocumentAdded((count) => count + 1);
  };

  const setDocumentUpdatedToTrue = () => {
    setDocumentUpdated((count) => count + 1);
  };

  const setDocumentDeletedToTrue = (documentList) => {
    setDocumentDeleted((count) => count + 1);
  };

  const changeDocumentFavouriteStateFunc = () => {
    setDocumentFavouriteState(!documentFavouriteState);
  };

  const filtervisibility = () => {
    setOpenFilter(!openFilter);
  };

  const applyFilters = () => {
    // setOpenFilter(false);
    setCurrentPage(0);
    setApi(
      `${baseUrl}/apireact/alldocument?limit=${limit}&offset=0&userId=${userID}&isAdmin=${isAdmin}${global_search}${document_type}${category_id}${case_number}${created_at}`
    );
  };

  const clearFilters = () => {
    // setOpenFilter(false);
    setGlobalSearch("");
    setSelectedCategoryOptions("");
    setSelectedFileTypeOptions("");
    setSearchedUserID("");
    setSearchedDate(null);
    setCurrentPage(0);
    setApi(
      `${baseUrl}/apireact/alldocument?limit=${limit}&offset=0&userId=${userID}&isAdmin=${isAdmin}`
    );
  };

  const handlePageClick = ({ selected: selectedPage }) => {
    setCurrentPage(selectedPage);
  };

  return (
    <div className="document-table">
      {showAddDocumentForm ? (
        <AddDocument
          onAddDocumentButtonClick={onAddDocumentButtonClick}
          setDocumentAddedToTrue={setDocumentAddedToTrue}
          categoryOptions={categoryOptions}
        />
      ) : (
        <div className="documents-content">
          <div className="table-header">
            <TextField
              name="globalSearch"
              label="Search"
              type="text"
              autoComplete="off"
              value={globalSearch}
              onChange={onGlobalSearchChange}
              variant="outlined"
              InputProps={{
                endAdornment: <AiOutlineSearch />,
              }}
            />
            <div className="doc-header-btn">
              <button className="filter-documents" onClick={filtervisibility}>
                <RiFilter2Fill />
              </button>
              <button
                className="add-document"
                onClick={onAddDocumentButtonClick}
              >
                Add Document
              </button>
            </div>
          </div>
          <div
            className={openFilter ? "document-filter" : "hide-document-filter"}
          >
            <BasicDatePicker
              value={searchedDate}
              setValue={onSearchedDateChange}
            />
            <Autocomplete
              id="select-category-filter"
              options={categoryOptions}
              sx={{ width: 154 }}
              renderInput={(params) => (
                <TextField {...params} label="Category" />
              )}
              value={selectedCategoryOptions}
              onChange={(e, selectedOption) =>
                onCategoryOptionChange(selectedOption)
              }
            />
            <Autocomplete
              id="select-file-option"
              options={fileTypeOptions}
              sx={{ width: 154 }}
              renderInput={(params) => (
                <TextField {...params} label="File Type" />
              )}
              value={selectedFileTypeOptions}
              onChange={(e, selectedOption) =>
                onFileTypeOptionChange(selectedOption)
              }
            />
            <TextField
              name="caseID"
              label="Case ID"
              type="text"
              autoComplete="off"
              value={searchedUserID}
              onChange={onSearchedUserIDChange}
              variant="outlined"
            />
            <ButtonUI
              formButton={false}
              variant="contained"
              buttonText="Apply"
              style={{
                width: "80px",
                height: "35px",
                color: "#007bff",
                backgroundColor: "white",
                boxShadow: "none",
                border: "1px solid #007bff",
                fontWeight: "500",
                borderRadius: "4px",
                letterSpacing: "0.07rem",
              }}
              onClickFunc={() => {
                applyFilters();
              }}
            ></ButtonUI>
            <ButtonUI
              formButton={false}
              variant="contained"
              buttonText="Reset"
              style={{
                width: "80px",
                height: "35px",
                color: "#007bff",
                backgroundColor: "white",
                boxShadow: "none",
                border: "1px solid #007bff",
                fontWeight: "500",
                borderRadius: "4px",
                letterSpacing: "0.07rem",
              }}
              onClickFunc={() => {
                clearFilters();
              }}
            ></ButtonUI>
          </div>
          {!loading ? (
            documentResponse.length > 0 ? (
              <div className="document__table">
                <Table
                  loading={loading}
                  openFilter={openFilter}
                  addDocumentTab={addDocumentTab}
                  changeDocumentFavouriteStateFunc={
                    changeDocumentFavouriteStateFunc
                  }
                  setDocumentUpdatedToTrue={setDocumentUpdatedToTrue}
                  setDocumentDeletedToTrue={setDocumentDeletedToTrue}
                  allFavourite={allFavourite}
                  documentTableData={documentResponse}
                  categoryOptions={categoryOptions}
                />
                <div className="table-pagination">
                  <ReactPaginate
                    previousLabel={<MdOutlineArrowBackIos />}
                    nextLabel={<MdOutlineArrowForwardIos />}
                    pageCount={pageCount}
                    onPageChange={handlePageClick}
                    forcePage={currentPage}
                    containerClassName={"doc-pagination"}
                    previousLinkClassName={"doc-pagination-link"}
                    nextLinkClassName={"doc-pagination-link"}
                    disabledClassName={"doc-pagination-link-disabled"}
                    activeClassName={"doc-pagination-link-active"}
                  />
                </div>
              </div>
            ) : (
              <h5 className="no-doc-data">No Data Available</h5>
            )
          ) : (
            <Loading />
          )}
        </div>
      )}
    </div>
  );
};

export default DocumentTable;

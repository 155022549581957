import React, { useMemo } from "react";
import { useTable, useSortBy, useRowSelect } from "react-table";
import { Columns } from "./CategoryColumns";
import EditCategoryPopup from "./EditCategoryPopup";
import DeleteCategoryPopup from "./DeleteCategoryPopup";
import Loading from "../material-ui/Loading";
import CheckBox from "./CheckBox";
import Dropdown from "./Dropdown";
import "../../styles/Table.css";
import { categories } from "../common/Categories";

const CategoryTable = ({
  loading,
  showFilter,
  setCategoryUpdatedToTrue,
  setCategoryDeletedToTrue,
  categoryTableData,
}) => {
  const columns = useMemo(() => Columns, []);
  const data = useMemo(() => categoryTableData, [categoryTableData]);

  const tableHooks = (hooks) => {
    hooks.visibleColumns.push((columns) => [
      ...columns,
      {
        Header: "Action",
        Cell: ({ row }) => {
          return (
            <>
              <EditCategoryPopup
                setCategoryUpdatedToTrue={setCategoryUpdatedToTrue}
                tableValues={row.values}
              />
              <DeleteCategoryPopup
                setCategoryDeletedToTrue={setCategoryDeletedToTrue}
                id={row.values.id}
              />
            </>
          );
        },
      },
    ]);
  };

  const {
    getTableProps,
    getTableBodyProps,
    headerGroups,
    rows,
    prepareRow,
    selectedFlatRows,
    getToggleAllRowsSelectedProps,
  } = useTable(
    {
      columns: columns,
      data: data,
      initialState: {
        hiddenColumns: ["id", "bg_color"],
      },
    },
    tableHooks,
    useSortBy,
    useRowSelect,
    (hooks) => {
      hooks.visibleColumns.push((columns) => {
        return [
          {
            id: "selection",
            Cell: ({ row }) => (
              <>
                <CheckBox {...row.getToggleRowSelectedProps()} />
              </>
            ),
          },
          ...columns,
        ];
      });
    }
  );

  const selectedFlatRowsID = selectedFlatRows.map(
    (row) => `'${row.values.id}'`
  );
  const categoryTableDataID = categoryTableData.map((row) => "tableData");

  const isEntireTableRowsSelected =
    selectedFlatRowsID.length === categoryTableDataID.length;

  const SelectionHeader = ({ getToggleAllRowsSelectedProps }) => {
    return (
      <div className="bulk-func">
        <CheckBox {...getToggleAllRowsSelectedProps()} />
        <Dropdown
          selectedRows={selectedFlatRowsID}
          isEntireTableRowsSelected={isEntireTableRowsSelected}
          setCategoryDeletedToTrue={setCategoryDeletedToTrue}
        />
      </div>
    );
  };

  return (
    <div className={showFilter ? "resized-table-content" : "table-content"}>
      {!loading ? (
        <table className="documents-table category-tables" {...getTableProps()}>
          <thead className="documents-table-header category-tables-header">
            {headerGroups.map((headerGroup, index) => (
              <tr key={index} {...headerGroup.getHeaderGroupProps()}>
                {headerGroup.headers.map((column, index) => (
                  <th key={index}>
                    <span>
                      {column.id == "selection" ? (
                        <SelectionHeader
                          getToggleAllRowsSelectedProps={
                            getToggleAllRowsSelectedProps
                          }
                        />
                      ) : (
                        column.render("Header")
                      )}
                    </span>
                  </th>
                ))}
              </tr>
            ))}
          </thead>
          <tbody
            className="documents-table-body category-tables-body"
            {...getTableBodyProps()}
          >
            {rows.map((row, index) => {
              prepareRow(row);
              return (
                <tr key={index} {...row.getRowProps()}>
                  {row.cells.map((cell, index) => {
                    if (cell.column.Header === "Badge") {
                      return (
                        <td key={index}>
                          <p
                            className="category"
                            style={{
                              background: `${
                                cell.row.values.bg_color || "#d7cbef"
                              }`,
                            }}
                          >
                            {cell.row.values.badge || "Other"}
                          </p>
                        </td>
                      );
                    } else {
                      return (
                        <td key={index} {...cell.getCellProps()}>
                          {cell.render("Cell")}
                        </td>
                      );
                    }
                  })}
                </tr>
              );
            })}
          </tbody>
        </table>
      ) : (
        <Loading />
      )}
    </div>
  );
};

export default CategoryTable;

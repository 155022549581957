import baseUrl from "../config/baseUrl";

const addDocument = async ({
  token,
  user_id,
  case_number,
  document_title,
  document_desc,
  category,
  document_name,
}) => {
  const formData = new FormData();
  formData.append("user_id", user_id);
  formData.append("case_number", case_number);
  formData.append("document_title", document_title);
  formData.append("document_desc", document_desc);
  formData.append("category", category);
  formData.append("document_name", document_name);

  try {
    const response = await fetch(`${baseUrl}/apireact/upload_documents`, {
      method: "POST",
      headers: {
        Accept: "application/json",
        Authorization: `Bearer ${token}`,
      },
      body: formData,
    });
    const data = await response.json();
    return data;
  } catch (error) {
    return error;
  }
};

export default addDocument;
